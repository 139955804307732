'use client';
import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import { EventNames, useAnalytics } from '@/Helpers/analytics';
import { AuthFormContext } from '@/components/Authentication/AuthFormProvider';
import Link from 'next/link';
import SubmitButton from '@/components/Authentication/SubmitButton';
import { signIn, useSession } from 'next-auth/react';
import resendConfirmationCode from '@/Services/Auth/resendConfirmationCode';
import * as Sentry from '@sentry/react';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { setError } = useContext(AuthFormContext);
  const router = useRouter();
  const analytics = useAnalytics();
  const session = useSession();

  useEffect(() => {
    console.log('Session: ', session);
  }, [session]);

  const handleLogin = async (event: any) => {
    setError('');
    event.preventDefault();
    setLoading(true);
    signIn('cognito', {
      username: email,
      password: password,
      redirect: false,
    })
      .then(response => {
        console.log('Response: ', response);
        if (!response) {
          setError('An error occurred. Please try again later.');
          setLoading(false);
          analytics?.track(EventNames.AuthError, {
            reason: 'Response was undefined.',
          });
          return;
        }
        if (response.error) {
          console.log('err:', response.error);
          if (response.error === 'UserNotConfirmedException') {
            sessionStorage.setItem('username-to-verify', email);
            try {
              fetch('/api/resendConfirmationCode', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
              })
                .then(resp => resp.json())
                .then(() => {
                  return fetch('/api/verifyRequest', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ password }),
                  });
                })
                .then(res => res.json())
                .then(res => {
                  sessionStorage.setItem('verify-creds', JSON.stringify(res));
                  sessionStorage.setItem(
                    'verify-info',
                    JSON.stringify({ email: email, type: 'signup' })
                  );
                  router.replace('/verify');
                });
            } catch (err2: any) {
              setLoading(false);
              if (err2.name === 'UserNotFoundException') {
                setError(err2.message);
              } else {
                setError('An error occurred. Please try again.');
              }
              Sentry.captureException(err2);
            }
          } else if (
            response.error === 'UserNotFoundException' ||
            response.error === 'NotAuthorizedException'
          ) {
            setError('Incorrect username or password');
          } else {
            setError('An error occurred. Please try again.');
          }
          Sentry.captureException(response.error);
          setLoading(false);
        } else {
          router.replace('/home');
          analytics?.track(EventNames.Login);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <form
      className={`w-full`}
      onSubmit={handleLogin}
      suppressHydrationWarning={true}
    >
      <div>Email</div>
      <input
        name={'email'}
        className={
          'h-10 w-full rounded-lg border border-gray-300 px-4 focus:border-voca-purple focus:outline-none'
        }
        value={email}
        onChange={value => setEmail(value.target.value)}
      />
      <div className={`mt-2`}>Password</div>
      <input
        name={'password'}
        className={
          'h-10 w-full rounded-lg border border-gray-300 px-4 focus:border-voca-purple focus:outline-none'
        }
        type={'password'}
        value={password}
        onChange={value => setPassword(value.target.value)}
      />
      <div className={'flex justify-end'}>
        <Link
          className={`cursor-pointer text-voca-purple hover:underline`}
          href={'/forgotpassword'}
        >
          Forgot Password
        </Link>
      </div>
      <SubmitButton label={'Login'} loading={loading} />
    </form>
  );
};

export default LoginForm;
