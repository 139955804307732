'use client';
import Image from 'next/image';
import googleLogo from '../../public/Google__G__Logo.svg';
import { signIn, getProviders } from 'next-auth/react';
import { useEffect, useState } from 'react';

export const GoogleButton = () => {
  const [providers, setProviders] = useState<any>(null);

  // Fetch providers on mount.
  useEffect(() => {
    getProviders().then(providers => setProviders(providers));
  }, []);

  return (
    <div
      tabIndex={0}
      className="flex h-12 cursor-pointer items-center justify-start rounded-lg border border-gray-300 bg-white transition-colors duration-100 hover:bg-gray-100"
      onClick={() =>
        signIn(providers['cognito_google'].id, { callbackUrl: '/home' })
      }
    >
      <div className={`flex aspect-square h-full items-center justify-center`}>
        <div className={`h-5 w-5`}>
          <Image src={googleLogo} alt={'Google Logo'} />
        </div>
      </div>
      Continue with Google
    </div>
  );
};
