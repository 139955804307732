'use client';

import { AuthFormContext } from '@/components/Authentication/AuthFormProvider';
import React, { useContext } from 'react';

const AuthError = () => {
  const { error } = useContext(AuthFormContext);
  if (error === '') return null;
  return <div className={`text-red-500`}>{error}</div>;
};

export default AuthError;
