import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import React from 'react';

interface SubmitButtonProps {
  label: string;
  loading?: boolean;
}

const SubmitButton = ({ label, loading = false }: SubmitButtonProps) => {
  return (
    <button
      className={
        'mt-4 flex h-10 w-full items-center justify-center rounded-lg bg-voca-purple text-white active:bg-voca-purple-700 disabled:bg-voca-purple-500'
      }
      type={'submit'}
      disabled={loading}
    >
      {loading ? (
        <AiOutlineLoading3Quarters
          style={{ animation: 'spin 1s linear infinite' }}
          className={`h-5 w-5 cursor-pointer`}
        />
      ) : (
        label
      )}
    </button>
  );
};

export default SubmitButton;
